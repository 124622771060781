import LeadzState from "./LeadzState";
import LeadManager from "./managers/LeadManager";
import LeadzItemsManager from "./managers/LeadzItemsManager";
import CookieManager from "./managers/CookieManager";
import ButtonManager from "./managers/ButtonManager";
import FormManager from "./managers/FormManager";
import { ILeadInfo, ILeadzEvents, ILeadzOptions, ILeadzState } from "./models/leadInterfaces";
import { ILeadzError, } from "./models/LeadzError";

/** Executes public Leadz logic. */
export class LeadzClient {
    private isExecuting: boolean = false;

    /** Gets Leadz events. */
    get events() : ILeadzEvents {
        return LeadzState.events;
    }

    /** Gets current Leadz state. */
    get state() : ILeadzState {
        return {
            lead: LeadzState.currentLeadInfo,
        };
    }

    /** Returns if tracing is enabled or not. */
    get isTrackingEnabled(): boolean {
        const trackingEnabledCookie = CookieManager.readTrackingEnabledCookie();
        return (
            trackingEnabledCookie !== null && trackingEnabledCookie === true
        );
    }

    /** Returns opt-out state. */
    get isOptOut(): boolean {
        const trackingEnabledCookie = CookieManager.readTrackingEnabledCookie();
        return (
            trackingEnabledCookie !== null && trackingEnabledCookie === false
        );
    }

    /**
     * Initializes Leadz script.
     * @param baseUrl - Leadz API base url.
     * @param campaignIdentifier - Unique campaign identifier.
     * @param trackingEnabled - Definies if Leadz should track the user - if no, then no request with user data should be send to Leadz system.
     */
    async initialize(
        baseUrl: string,
        campaignIdentifier: string,
        options?: ILeadzOptions
    ): Promise<void> {
        if (options) {
            this.setOptions(options);
        }

        if (LeadzState.debug) console.log("Leadz DEBUG mode enabled.");

        // Give a tracking priority to cookie.
        LeadzState.trackingEnabled = false;
        const tracingEnabledCookie = CookieManager.readTrackingEnabledCookie();

        if (tracingEnabledCookie) {
            LeadzState.trackingEnabled = tracingEnabledCookie;
        }

        LeadzState.initialize(baseUrl, campaignIdentifier);
        await LeadManager.reloadLeadData();

        await LeadzItemsManager.loadLeadzItems();
        ButtonManager.applypropertiesToHtmlButtons();

        await LeadManager.tryActivateLead();

        if (LeadzState.refreshItemsOnDomChange) {
            const domObserver = new MutationObserver((mutations, observer) => {
                observer.disconnect();
                ButtonManager.applypropertiesToHtmlButtons();
                observer.observe(document.body, {
                    childList: true,
                    subtree: true,
                });
            });
            domObserver.observe(document.body, {
                childList: true,
                subtree: true,
            });
        }

        LeadzState.events.onInitialized?.();
        if (LeadzState.debug)
            console.log("Leadz - initialization finished.", LeadzState);
    }
    /** Explicitly sets leadz options.
     * @param options - Leadz options.
     */
    setOptions(options: ILeadzOptions) {
        if (options.debug !== undefined) LeadzState.debug = options.debug;

        if (options.callLastClickedAfterLogin !== undefined)
            LeadzState.callLastClickedAfterLogin =
                options.callLastClickedAfterLogin;

        if (options.refreshItemsOnDomChange !== undefined)
            LeadzState.refreshItemsOnDomChange =
                options.refreshItemsOnDomChange;

        if (options.fetchScData !== undefined)
            LeadzState.fetchScData = options.fetchScData;

        if (options.trackingEnabledCookieExpirationDays !== undefined)
            LeadzState.trackingEnabledCookieExpirationDays =
                options.trackingEnabledCookieExpirationDays;
    }

    /**  Executes Leadz Form logic.
     * 
     * @param e 
     * @param onError - Function called on error. *IMPORTANT* The function is called only in case of Leadz-related error.
     * In other cases, like eg. connection problems (server down?), error is logged to the console (only if DEBUG is enabled).
     * @returns 
     */
    async sendForm(e: Event, onError?: (error: ILeadzError, sender: HTMLFormElement) => void) {
        e.preventDefault();
        if (this.isExecuting) {
            if (LeadzState.debug)
                console.warn(
                    "Exiting from SENDFORM action - Leadz is currently executing."
                );
            return;
        }

        this.isExecuting = true;
        try {
            await FormManager.executeForm(
                e.currentTarget as HTMLFormElement,
                onError
            );
        } finally {
            this.isExecuting = false;
        }
    }

    /** Executes Leadz Button / SmartButton logic. */
    async executor(htmlElement: Element) {
        if (this.isExecuting) {
            if (LeadzState.debug)
                console.warn(
                    "Exiting from EXECUTOR action - Leadz is currently executing."
                );
            return;
        }

        this.isExecuting = true;
        try {
            await ButtonManager.executeButton(htmlElement);
        } finally {
            this.isExecuting = false;
        }
    }

    /** Logging-out the Lead. */
    async logout(): Promise<void> {
        CookieManager.removeAccessTokenCookie();
        await LeadManager.reloadLeadData();
        LeadzState.events.onLoggedOut?.();
        if (LeadzState.debug) console.log("Leadz - logged-out.");
    }

    /** Enablind tracking (in case if it is disabled).
     * Raises event "onTrackingStateChanged"
     */
    async enableTracking(): Promise<void> {
        CookieManager.setTrackingEnabledCookie(true);

        if (LeadzState.trackingEnabled) return;

        LeadzState.trackingEnabled = true;
        if (LeadzState.initialized) {
            await LeadManager.reloadLeadData();
            ButtonManager.applypropertiesToHtmlButtons();
            LeadzState.events.onTrackingStateChanged?.(true);
        }

        if (LeadzState.debug) console.log("Leadz - tracking enabled.");
    }

    /** Opting OUT from the tracking.
     * The function disables Leadz tracking functionallity until its enabled agin using "enableTracking()" method or removing cookie.
     * Raises event "onTrackingStateChanged" */
    async disableTracking(): Promise<void> {
        CookieManager.setTrackingEnabledCookie(false);
        if (!LeadzState.trackingEnabled) return;

        LeadzState.trackingEnabled = false;

        if (LeadzState.initialized) {
            await LeadManager.reloadLeadData();
            LeadzState.events.onTrackingStateChanged?.(false);
        }

        if (LeadzState.debug) console.log("Leadz - tracking disabled.");
    }
}

// Exports are required by typedoc.
export { ILeadzEvents, ILeadzState, ILeadzOptions, ILeadInfo, ILeadzError };
// Default module export.
export default new LeadzClient();
