import { API_URL_PART, LEADZ_ITEM_TYPES } from "../consts";
import IFormHandler from "../formHandlers/IFormHandler";
import LoginFormHandler from "../formHandlers/LoginFormHandler";
import { getParamValue, leadzFetch, splitAttribute } from "../helpers";
import LeadzState from "../LeadzState";
import { IButton, IButtonInfo } from "../models/buttonInterfaces";
import { FormType } from "../models/enums";
import { IForm } from "../models/formInterfaces";
import { ILeadzError, LeadzError } from "../models/LeadzError";
import ButtonManager from "./ButtonManager";
import LeadzItemsManager from "./LeadzItemsManager";

export interface IExecutionFormData {
    formHtmlElement: HTMLFormElement;
    leadzForm: IForm;
    leadzButton: {
        buttonHtmlElement: Element;
        leadzButton: IButton;
        currentState: IButtonInfo;
    };
    formElements: Array<any>;
}

class FormManager {
    async executeForm(
        htmlElem: HTMLFormElement,
        onError?: (error: ILeadzError, sender: HTMLFormElement) => void
    ) {
        const leadzFormData = this.getLeadzFormData(htmlElem);

        if (!leadzFormData){
            if (LeadzState.debug){
                console.error("Could not execute leadz form because form can not be initialized properly.", htmlElem);
            }
            return;
        }

        if (!LeadzState.trackingEnabled) {
            if (LeadzState.debug)
                console.warn(
                    "Trying to use Leadz (form) despite tracking is disabled.",
                    leadzFormData.leadzForm
                );

            LeadzState.events.onTrackingDisabledUseAttempt?.(
                leadzFormData.leadzForm, htmlElem
            );
            return;
        }

        const sendFormUrl = new URL(
            `${API_URL_PART}/ExecuteForm`,
            LeadzState.baseUrl
        );

        try {
            const result = await leadzFetch(
                sendFormUrl.href,
                {
                    method: "POST",
                },
                {
                    formId: leadzFormData.leadzForm.id,
                    elements: leadzFormData.formElements,
                    button: leadzFormData.leadzButton.currentState,
                }
            );
            this.parseFormResult(result, leadzFormData);
        } catch (error) {
            if (error instanceof LeadzError)
                onError?.(error, htmlElem);

            if (LeadzState.debug) console.error(error);
        }
    }

    private async parseFormResult(
        formResponse: any,
        leadzFormData: IExecutionFormData
    ): Promise<void> {
        let handler: IFormHandler | null = null;
        switch (leadzFormData.leadzForm.formType) {
            case FormType.Login:
                handler = LoginFormHandler;
                break;
        }
        await handler?.handle(formResponse.formResult);

        ButtonManager.parseButtonResult(
            formResponse.buttonResult,
            leadzFormData.leadzButton.buttonHtmlElement
        );
    }

    private getLeadzFormData(formElement: HTMLFormElement): IExecutionFormData | null {
        const leadzAttributeSplitted = splitAttribute(
            LEADZ_ITEM_TYPES.form.attributeName,
            formElement
        );

        const formId =
            leadzAttributeSplitted[leadzAttributeSplitted.length - 1]; // Id always at the end.

        const leadzForm = LeadzItemsManager.leadzForms.find(
            (n) => n.id.toLowerCase() === formId.toLowerCase()
        );

        if (!leadzForm) {
            console.error("Could not find proper Leadz form for the HTML element.", formElement);
            return null;
        }
            

        const submitElement = formElement.querySelector(
            `input[type=submit][${LEADZ_ITEM_TYPES.button.attributeName}], button[type=submit][${LEADZ_ITEM_TYPES.button.attributeName}]`
        );

        if (!submitElement) {
            console.error("Could not find proper Leadz submit button for the form.", formElement);
            return null;
        }
            

        const leadzButtonInfo =
            ButtonManager.getLeadzExecutionButtonInfo(submitElement);

        if (!leadzButtonInfo){
            if (LeadzState.debug){
                console.error(
                    "FormSubmitButton - Couldn't find Leadz (submit-button) for the html element.",
                    submitElement
                );
            }
            return null;
        }

        const formElements: Array<any> = [];

        // Form inputs elements
        var elements = formElement.querySelectorAll("input, select, textarea");

        for (var i = 0; i < elements.length; ++i) {
            var element = elements[i] as any;
            var name = element.name;
            var value = element.value;
            if (element.type == "radio") {
                value = this.getRadioValue(element, elements);
            } else if (element.type == "checkbox") {
                value = this.getCheckboxValue(element);
            }
            const elems = formElements.filter((n) => n.key === name);
            if (name && elems.length == 0) {
                formElements.push({ key: name, value: value });
            }
        }

        // Custom elements
        if (leadzForm.formType === FormType.RepeatPassword) {
            const resetPasswordCode = getParamValue("resetpasswordcode");
            const leadzUserIdentifier = getParamValue("leadzuseridentifier");

            if (!resetPasswordCode || !leadzUserIdentifier)
                throw new Error(
                    "Missing 'resetpasswordcode' or 'leadzuseridentifier' parameters."
                );

            formElements.push({
                key: "resetCode",
                value: resetPasswordCode,
            });
            formElements.push({
                key: "userId",
                value: leadzUserIdentifier,
            });
        }

        return {
            formHtmlElement: formElement,
            leadzForm: leadzForm,
            leadzButton: {
                ...leadzButtonInfo,
                buttonHtmlElement: submitElement,
            },
            formElements: formElements,
        };
    }

    private getRadioValue(element: any, elements: NodeListOf<any>) {
        var name = element.name;
        for (var i = 0; i < elements.length; i++) {
            var subElement = elements[i];
            if (subElement.name == name && subElement.checked) {
                return subElement.value;
            }
        }
        return "";
    }

    private getCheckboxValue(element: any) {
        var checked = element.checked;
        return checked ? "true" : "false";
    }
}

export default new FormManager();
