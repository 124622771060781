export enum ButtonTag {
    Normal = 0,
    Smart = 1,
}

export enum ButtonType {
    OpenUrl = 0,
    ExecuteCallback = 1,
}

export enum FormType {
    Login = 0,
    Register = 1,
    ForgotPassword = 2,
    RepeatPassword = 3,
    Profile = 5,
    Custom = 7,
}
