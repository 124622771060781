export const API_SCRIPTS_PART = "/api/PublicScripts";
export const API_URL_PART = "/api/V2External";
export const LEADZ_ITEM_TYPES = {
    button: {
        name: "button",
        attributeName: "data-leadz",
        path: "NtcButton.js",
    },
    form: {
        name: "form",
        attributeName: "data-leadz-form-id",
        path: "NtcForm.js",
    },
};
