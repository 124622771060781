import LeadzState from "./LeadzState";
import CookieManager from "./managers/CookieManager";
import { LeadzError } from "./models/LeadzError";

export class FetchError extends Error {
    result: any;

    constructor(message: string, result: any) {
        super(message);
        this.result = result;
    }
}

export const leadzFetch = async (
    input: RequestInfo,
    init?: RequestInit,
    body?: any
): Promise<any> => {
    if (!LeadzState.trackingEnabled) {
        if (LeadzState.debug)
            console.error(
                "Trying to fetch despite the Leadz tracking is disabled."
            );
        return Promise.reject();
    }

    if (!init) init = {};

    const headers = new Headers(init?.headers);

    if (!headers.has("X-Referrer")) headers.append("X-Referrer", location.href);

    if (!headers.has("Authorization")) {
        const accessToken = CookieManager.readAccessTokenCookie();
        if (accessToken)
            headers.append("Authorization", `Bearer ${accessToken}`);
    }

    if (init.method === "POST") {
        if (!headers.has("Content-type"))
            headers.append("Content-type", "application/json");

        const visitorIdentifier = CookieManager.readVisitorFromCookie();

        init.body = JSON.stringify({
            ...body,
            campaignIdentifier: LeadzState.campaignIdentifier,
            visitorIdentifier: isGuid(visitorIdentifier)
                ? visitorIdentifier
                : null,
        });
    }

    init.headers = headers;

    const response = await fetch(input, init);
    const responseJson = await response.json();
    if (response.status !== 200) {
        if (responseJson.__leadz) {
            const err = responseJson.error;
            const leadzError = new LeadzError({
                code: err.code,
                details: err.details,
                message: err.message
            });

            throw leadzError;
        } else {
            throw responseJson;
        }
    }

    return responseJson.result;
};

export const getParamValue = (key: string): string | null => {
    const params = new URLSearchParams(window.location.search);
    const paramsArray = Array.from<string[]>(params as any);

    return (
        paramsArray.find(
            (p) => p[0].toLowerCase() === key.toLowerCase()
        )?.[1] ?? null
    );
};

const isGuid = (str: string | null | undefined) => {
    if (str === null || str === undefined || str.length === 0) return false;

    var regex = /^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/;
    return regex.test(str);
};

export const splitAttribute = (
    attributeName: string,
    element: Element
): Array<string> => {
    return element.getAttribute(attributeName)!.toLowerCase().split("_");
};
