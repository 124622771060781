export interface ILeadzError {
    /** Error code. */
    code: number,
    /** Error message. */
    message: string,
    /** Error details. (Usually null) */
    details: string | null
} 

export class LeadzError implements ILeadzError {
    code: number;
    message: string;
    details: string | null;

    constructor(init: ILeadzError) {
        this.code = init.code;
        this.message = init.message;
        this.details = init.details;
    }
}