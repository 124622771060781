import { LEADZ_ITEM_TYPES } from "../consts";
import { splitAttribute } from "../helpers";
import LeadzState from "../LeadzState";
import { IExecutionButtonInfo } from "./ButtonManager";
import CookieManager from "./CookieManager";

class LastClickedExecutionManager {
    trySaveClieckedButton(executionButtonInfo: IExecutionButtonInfo) {
        if (
            LeadzState.callLastClickedAfterLogin &&
            !LeadzState.currentLeadInfo.isLogged &&
            !executionButtonInfo.isFormButton
        ) {
            CookieManager.setLastClickedButtonIdentifier(
                executionButtonInfo.leadzButton.identifier
            );
        }
    }

    tryGetLastClickedButton() {
        if (!LeadzState.callLastClickedAfterLogin) return null;

        const lastClickedButtonIdentifier =
            CookieManager.readLastClickedButtonIdentifier();

        if (!lastClickedButtonIdentifier) return null;

        const buttons = document.querySelectorAll(
            `[${LEADZ_ITEM_TYPES.button.attributeName}]`
        );

        let lastClickedButtonElement: Element | undefined;
        for (let i = 0; i < buttons.length; i++) {
            const buttonElem = buttons[i];
            const attributeSplitted = splitAttribute(
                LEADZ_ITEM_TYPES.button.attributeName,
                buttonElem
            );
            const buttonId = attributeSplitted[attributeSplitted.length - 1];
            if (
                buttonId.toLocaleLowerCase() ===
                lastClickedButtonIdentifier.toLocaleLowerCase()
            ) {
                lastClickedButtonElement = buttonElem;
                break;
            }
        }

        if (lastClickedButtonElement) {
            return lastClickedButtonElement;
        } else {
            if (LeadzState.debug)
                console.warn(
                    "Can't find last clicked button to execute.",
                    lastClickedButtonIdentifier
                );
            return null;
        }
    }

    removeLastClickedButton() {
        CookieManager.removeLastClickedButtonIdentifier();
    }
}

export default new LastClickedExecutionManager();
