import { API_SCRIPTS_PART, LEADZ_ITEM_TYPES } from "../consts";
import LeadzState from "../LeadzState";
import { IButton } from "../models/buttonInterfaces";
import { IForm } from "../models/formInterfaces";

class LeadzItemsManager {
    leadzButtons: Array<IButton> = [];
    leadzForms: Array<IForm> = [];

    async loadLeadzItems(): Promise<void> {
        this.leadzButtons = await this.loadButtons();
        this.leadzForms = await this.loadForms();
    }

    private async loadButtons(): Promise<Array<IButton>> {
        if (LeadzState.debug) console.log("Leadz - loading buttons.");

        const buttonsUrl = new URL(
            `${API_SCRIPTS_PART}/${LeadzState.campaignIdentifier}/${LEADZ_ITEM_TYPES.button.path}`,
            LeadzState.baseUrl
        );
        const buttons = (await fetch(buttonsUrl.href).then((response) =>
            response.json()
        )) as Array<IButton>;

        return buttons;
    }

    private async loadForms(): Promise<Array<IForm>> {
        if (LeadzState.debug) console.log("Leadz - loading forms.");

        const formsUrl = new URL(
            `${API_SCRIPTS_PART}/${LeadzState.campaignIdentifier}/${LEADZ_ITEM_TYPES.form.path}`,
            LeadzState.baseUrl
        );
        const forms = (await fetch(formsUrl.href).then((response) =>
            response.json()
        )) as Array<IForm>;

        return forms;
    }
}

export default new LeadzItemsManager();
