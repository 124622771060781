import LeadzState from "../LeadzState";
import CookieManager from "../managers/CookieManager";
import LeadManager from "../managers/LeadManager";
import IFormHandler from "./IFormHandler";
import LastClieckedExecutionManager from "../managers/LastClickedExecutionManager";
import ButtonManager from "../managers/ButtonManager";

class LoginFormHandler implements IFormHandler {
    async handle(loginResult: any): Promise<void> {
        if (!loginResult) return;

        const tokenExpiresAt = new Date();
        tokenExpiresAt.setSeconds(
            tokenExpiresAt.getSeconds() + loginResult.expireInSeconds
        );

        const accessToken = loginResult.accessToken;

        CookieManager.setAccessTokenCookie(accessToken, tokenExpiresAt);

        await LeadManager.reloadLeadData();
        // applypropertiesToHtmlButtons();

        LeadzState.events.onLoggedIn?.();

        const lastClickedButtonToExecute =
            LastClieckedExecutionManager.tryGetLastClickedButton();
        if (lastClickedButtonToExecute) {
            ButtonManager.executeButton(lastClickedButtonToExecute);
            LastClieckedExecutionManager.removeLastClickedButton();
        }
    }
}

export default new LoginFormHandler();
