import { API_URL_PART, LEADZ_ITEM_TYPES } from "../consts";
import { leadzFetch, splitAttribute } from "../helpers";
import LeadzState from "../LeadzState";
import { IButton, IButtonInfo, ISmartButton } from "../models/buttonInterfaces";
import { ButtonTag, ButtonType } from "../models/enums";
import { ILeadInfo } from "../models/leadInterfaces";
import { ISmartRule, SmartRuleType } from "../models/smartRuleInterfaces";
import CookieManager from "./CookieManager";
import LeadzItemsManager from "./LeadzItemsManager";

export interface IExecutionButtonInfo {
    buttonHtmlElement: Element;
    leadzButton: IButton;
    currentState: IButtonInfo;
    isFormButton: boolean;
}

class ButtonManager {
    executeButton(htmlElement: Element) {
        const leadzButton = this.getLeadzExecutionButtonInfo(htmlElement);

        if (!leadzButton) {
            if (LeadzState.debug) {
                console.error(
                    "ExecuteButton - Couldn't find Leadz (button) for the html element.",
                    htmlElement
                );
            }
            return Promise.resolve();
        }

        return this.executeLeadzButton(leadzButton);
    }

    applypropertiesToHtmlButtons() {
        const buttonElements = document.querySelectorAll(
            `[${LEADZ_ITEM_TYPES.button.attributeName}]`
        );

        for (let i = 0; i < buttonElements.length; i++) {
            const htmlElement = buttonElements[i];
            const executionInfo = this.getLeadzExecutionButtonInfo(htmlElement);

            if (!executionInfo){
                if (LeadzState.debug) {
                    console.error(
                        "ApplyProperties - Couldn't find Leadz (button) for the html element.",
                        htmlElement
                    );
                }
                continue;
            }

            if (!executionInfo.isFormButton) {
                buttonElements[i].setAttribute(
                    "onclick",
                    "leadz.executor(this)"
                );
            }
        }
    }

    getLeadzExecutionButtonInfo(buttonElement: Element): IExecutionButtonInfo | null {
        const buttonAttributeSplitted = splitAttribute(
            LEADZ_ITEM_TYPES.button.attributeName,
            buttonElement
        );

        const submitButtonId =
            buttonAttributeSplitted[buttonAttributeSplitted.length - 1];

        const leadzButton = LeadzItemsManager.leadzButtons.find(
            (n) => n.identifier.toLowerCase() === submitButtonId.toLowerCase()
        );

        if (!leadzButton) {
            return null;
        }

        const smartButton = this.lookForSmartButton(
            leadzButton,
            LeadzState.currentLeadInfo
        );

        let currentState: IButtonInfo;
        if (smartButton) {
            currentState = {
                tag: ButtonTag.Smart,
                type: smartButton.type,
                identifier: smartButton.identifier,
            };
        } else {
            currentState = {
                tag: ButtonTag.Normal,
                type: leadzButton.type,
                identifier: leadzButton.identifier,
            };
        }

        return {
            buttonHtmlElement: buttonElement,
            leadzButton: leadzButton,
            currentState: currentState,
            isFormButton: !!(buttonElement as any).form,
        };
    }

    private executeLeadzButton = async (
        executionButtonInfo: IExecutionButtonInfo
    ) => {
        if (!LeadzState.trackingEnabled) {
            if (LeadzState.debug)
                console.warn(
                    "Trying to use Leadz (button) despite tracking is disabled.",
                    executionButtonInfo.leadzButton
                );

            LeadzState.events.onTrackingDisabledUseAttempt?.(
                executionButtonInfo.leadzButton, executionButtonInfo.buttonHtmlElement
            );
            return;
        }

        if (
            LeadzState.callLastClickedAfterLogin &&
            !executionButtonInfo.isFormButton &&
            executionButtonInfo.currentState.tag === ButtonTag.Normal
        ) {
            CookieManager.setLastClickedButtonIdentifier(
                executionButtonInfo.currentState.identifier
            );
        }

        const executeButtonUrl = new URL(
            `${API_URL_PART}/ExecuteButton`,
            LeadzState.baseUrl
        );

        try {
            const result = await leadzFetch(
                executeButtonUrl.href,
                {
                    method: "POST",
                },
                {
                    ...executionButtonInfo.currentState,
                }
            );
            this.parseButtonResult(
                result,
                executionButtonInfo.buttonHtmlElement
            );
        } catch (error) {
            LeadzState.events.onError?.(error);
            if (LeadzState.debug) console.error(error);
        }
    };

    parseButtonResult(buttonResult: any, buttonElement: Element) {
        switch (buttonResult.buttonType) {
            case ButtonType.OpenUrl:
                let validatedUrl = buttonResult.typeParameter as string;
                let openInNewWindow = false;
                if (validatedUrl.startsWith("_blank:")) {
                    openInNewWindow = true;
                    validatedUrl = validatedUrl.substring(7); // Remove "_blank:"
                }

                if (validatedUrl.substring(0, 4) === "www.")
                    validatedUrl = "http://" + validatedUrl;
                if (openInNewWindow) {
                    window.open(validatedUrl)?.focus();
                } else window.location.href = validatedUrl;

                break;
            case ButtonType.ExecuteCallback:
                const callback = buttonResult.typeParameter;
                const func = new Function("button", "arg", callback);
                func(buttonElement, buttonElement.getAttribute("leadz-arg"));
                break;
        }
    }

    private lookForSmartButton(
        leadzButton: IButton,
        leadInfo: ILeadInfo
    ): ISmartButton | null {
        if (leadzButton.smartButtons.length === 0) return null;

        for (let idx = 0; idx < leadzButton.smartButtons.length; idx++) {
            const smart = leadzButton.smartButtons[idx];
            if (this.checkSmartButtonConditions(smart, leadInfo)) {
                return smart;
            }
        }
        return null;
    }

    private checkSmartButtonConditions(
        smartButton: ISmartButton,
        leadInfo: ILeadInfo
    ): boolean {
        for (var i = 0; i < smartButton.smartRuleGroups.length; i++) {
            var smartRules = smartButton.smartRuleGroups[i].smartRules;
            var isSmartGroupTrue = true;
            for (var j = 0; j < smartRules.length; j++) {
                if (!this.checkSmartRule(smartRules[j], leadInfo)) {
                    isSmartGroupTrue = false;
                    break;
                }
            }
            if (isSmartGroupTrue) return true;
        }
        return false;
    }

    private checkSmartRule(
        smartRule: ISmartRule,
        leadInfo: ILeadInfo
    ): boolean {
        switch (smartRule.type) {
            case SmartRuleType.Language:
                let leadLanguage = leadInfo.language;
                if (!leadLanguage)
                    leadLanguage = navigator.languages
                        ? navigator.languages[0]
                        : navigator.language;

                const ruleLanguage = smartRule.ruleArgument;
                if (ruleLanguage && leadLanguage)
                    return (
                        ruleLanguage.split("-")[0] ===
                        leadLanguage.split("-")[0]
                    );
                return false;
            case SmartRuleType.AuthStatus:
                const ruleArgumentBool = JSON.parse(
                    smartRule.ruleArgument.toLowerCase()
                ) as boolean;
                return ruleArgumentBool === leadInfo.isLogged;
            case SmartRuleType.GlobalScore:
                if (!this.isNumeric(smartRule.ruleArgument)) return false;

                const ruleScore = parseInt(smartRule.ruleArgument);
                switch (smartRule.ruleComparator.toLowerCase()) {
                    case "equal":
                        return leadInfo.globalScore === ruleScore;
                    case "notequal":
                        return leadInfo.globalScore !== ruleScore;
                    case "greather":
                        return leadInfo.globalScore > ruleScore;
                    case "greatherequal":
                        return leadInfo.globalScore >= ruleScore;
                    case "smaller":
                        return leadInfo.globalScore < ruleScore;
                    case "smallerequal":
                        return leadInfo.globalScore <= ruleScore;
                }

                if (LeadzState.debug) {
                    console.warn(
                        "GlobalScore rule comparator not handled.",
                        smartRule
                    );
                }

                return false;
            case SmartRuleType.LocalScore:
                // not implemented
                if (LeadzState.debug) {
                    console.warn(
                        "Attempt to use not implemented LocalScore.",
                        smartRule
                    );
                }
                return true;
            case SmartRuleType.ScSubscribe:
                if (!this.isNumeric(smartRule.ruleArgument)) return false;

                const ruleScListId = parseInt(smartRule.ruleArgument);
                switch (smartRule.ruleComparator.toLowerCase()) {
                    case "contains":
                        return leadInfo.scLists.some((n) => n === ruleScListId);
                    case "notcontains":
                        return !leadInfo.scLists.some(
                            (n) => n === ruleScListId
                        );
                }

                if (LeadzState.debug) {
                    console.warn(
                        "SalesCommunicator rule comparator not handled.",
                        smartRule
                    );
                }
        }
        return false;
    }

    private isNumeric(str: string) {
        return !isNaN(parseFloat(str));
    }
}

export default new ButtonManager();
