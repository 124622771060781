import LeadzState from "../LeadzState";

const COOKIE_VISITOR_NAME = "leadzVisitorLead";
const COOKIE_ACCESS_TOKEN = "leadzLeadAccessToken";
const COOKIE_LAST_CLICKED_BUTTON_IDENTIFIER = "leadzLastButtonIdentifier";
const COOKIE_TRACKING_ENABLED = "leadzEnableTracking";

class CookieManager {
    readAccessTokenCookie(): string | null {
        const cookie = this.getCookie(COOKIE_ACCESS_TOKEN);
        return cookie;
    }

    setAccessTokenCookie(accessToken: string, expiration: Date): void {
        this.setCookie(COOKIE_ACCESS_TOKEN, accessToken, expiration);
    }

    removeAccessTokenCookie(): void {
        this.removeCookie(COOKIE_ACCESS_TOKEN);
    }

    readVisitorFromCookie(): string | null {
        const cookie = this.getCookie(COOKIE_VISITOR_NAME);
        if (!cookie || cookie === "") return null;
        return cookie;
    }

    seVisitorLeadCookie(visitorIdentifier: string): void {
        this.setCookie(COOKIE_VISITOR_NAME, visitorIdentifier);
    }

    removeVisitorCookie(): void {
        this.removeCookie(COOKIE_VISITOR_NAME);
    }

    readLastClickedButtonIdentifier(): string | null {
        const cookie = this.getCookie(COOKIE_LAST_CLICKED_BUTTON_IDENTIFIER);
        return cookie;
    }

    setLastClickedButtonIdentifier(accessToken: string): void {
        this.setCookie(COOKIE_LAST_CLICKED_BUTTON_IDENTIFIER, accessToken);
    }

    removeLastClickedButtonIdentifier(): void {
        this.removeCookie(COOKIE_LAST_CLICKED_BUTTON_IDENTIFIER);
    }

    readTrackingEnabledCookie(): boolean | null {
        const cookie = this.getCookie(COOKIE_TRACKING_ENABLED);
        if (cookie === null) return null;

        return JSON.parse(cookie);
    }

    setTrackingEnabledCookie(state: boolean): void {
        const expiration = new Date();
        expiration.setDate(
            expiration.getDate() +
                LeadzState.trackingEnabledCookieExpirationDays
        );

        this.setCookie(
            COOKIE_TRACKING_ENABLED,
            JSON.stringify(state),
            expiration
        );
    }

    removeTrackingEnabledCookie(): void {
        this.removeCookie(COOKIE_TRACKING_ENABLED);
    }

    private getCookie(name: string): string | null {
        name = name + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
    }

    private setCookie(name: string, value: string, expiration?: Date) {
        let d: Date | undefined = expiration;

        if (!d) {
            d = new Date();
            d.setDate(d.getDate() + 50);
        }

        document.cookie =
            name +
            "=" +
            value +
            ";" +
            "path=/;" +
            "expires=" +
            d.toUTCString() +
            ";SameSite=Strict;";
    }

    private removeCookie(name: string) {
        document.cookie =
            name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
    }
}

export default new CookieManager();
