import { API_URL_PART } from "../consts";
import LoginFormHandler from "../formHandlers/LoginFormHandler";
import { getParamValue, leadzFetch } from "../helpers";
import LeadzState from "../LeadzState";
import { ILeadInfo } from "../models/leadInterfaces";
import CookieManager from "./CookieManager";

class LeadManager {
    async reloadLeadData(): Promise<void> {
        if (!LeadzState.trackingEnabled) {
            if (LeadzState.debug) {
                console.log(
                    "Leadz tracing is disabled. Clearing leadz cookies."
                );
            }

            this.clearLeadData();
            return;
        }

        try {
            const leadInfo = await this.getLeadInfo();
            CookieManager.seVisitorLeadCookie(leadInfo.visitorIdentifier);
            LeadzState.currentLeadInfo = leadInfo;
        } catch (error) {
            LeadzState.events.onError?.(error);
            if (LeadzState.debug) {
                console.error(
                    "Couldn't fetch Lead data - clearing lead data.",
                    error
                );
            }
            this.clearLeadData();
        }
    }

    async tryActivateLead(): Promise<any> {
        const confirmationCode = getParamValue("leadzconfirmationcode");
        const leadIdentifier = getParamValue("leadzuseridentifier");

        if (confirmationCode && leadIdentifier) {
            const activationUrl = new URL(
                `${API_URL_PART}/ActivateLead`,
                LeadzState.baseUrl
            );
            const activationResponse = await leadzFetch(
                activationUrl.href,
                {
                    method: "POST",
                },
                {
                    leadzConfirmationCode: confirmationCode,
                    leadzUserIdentifier: leadIdentifier,
                }
            );

            if (activationResponse.loginResult) {
                await LoginFormHandler.handle(activationResponse.loginResult);
            }
            LeadzState.events.onActivated?.();
        }
        return Promise.resolve();
    }

    private async getLeadInfo(): Promise<ILeadInfo> {
        const leadInfoUrl = new URL(
            `${API_URL_PART}/LeadInfo`,
            LeadzState.baseUrl
        );

        return leadzFetch(
            leadInfoUrl.href,
            {
                method: "POST",
            },
            {
                fetchScData: LeadzState.fetchScData,
            }
        );
    }

    private clearLeadData(): void {
        CookieManager.removeLastClickedButtonIdentifier();
        CookieManager.removeAccessTokenCookie();
        CookieManager.removeVisitorCookie();
        LeadzState.resetCurrentLeadInfo();
    }
}

export default new LeadManager();
