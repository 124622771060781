import { IButton } from "./models/buttonInterfaces";
import { IForm } from "./models/formInterfaces";
import { ILeadInfo, ILeadzEvents } from "./models/leadInterfaces";

const initialLeadInfo = {
    isLogged: false,
    globalScore: 0,
    visitorIdentifier: "00000000-0000-0000-0000-000000000000",
    scLists: [],
    properties: {},
    tags: []
};

/** Stores actual Leadz state - need to be initialized before usage */
class LeadzState {
    callLastClickedAfterLogin: boolean = false;
    events: ILeadzEvents = {};

    private _baseUrl!: string;
    private _campaignIdentifier!: string;
    private _initialized: boolean = false;

    debug: boolean = false;
    fetchScData: boolean = false;
    trackingEnabledCookieExpirationDays: number = 7;
    refreshItemsOnDomChange: boolean = false;
    trackingEnabled!: boolean;
    currentLeadInfo: ILeadInfo = { ...initialLeadInfo };

    get baseUrl(): string {
        return this._baseUrl;
    }

    get campaignIdentifier(): string {
        return this._campaignIdentifier;
    }

    get initialized(): boolean {
        return this._initialized;
    }

    initialize(baseUrl: string, campaignIdentifier: string) {
        if (this._initialized) {
            throw Error("Leadz has been already initialized.");
        }

        this._baseUrl = baseUrl;
        this._campaignIdentifier = campaignIdentifier;
        this._initialized = true;
    }

    resetCurrentLeadInfo(): void {
        this.currentLeadInfo = { ...initialLeadInfo };
    }
}

export default new LeadzState();
