export enum SmartRuleType {
    Language = 0,
    AuthStatus = 1,
    GlobalScore = 2,
    LocalScore = 3,
    ScSubscribe = 4,
}

export interface ISmartRule {
    type: SmartRuleType;
    ruleComparator: string;
    ruleArgument: string;
    priority: number;
}

export interface ISmartRuleGroup {
    priority: number;
    smartRules: Array<ISmartRule>;
}
